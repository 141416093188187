$(function() {
  $('.mail-content-copybtn').on('click', function() {
    var copyTarget = document.querySelector('#copyTarget');
    var range = document.createRange();
    var selection = window.getSelection();

    range.selectNode(copyTarget);
    selection.removeAllRanges();
    selection.addRange(range);

    if (document.execCommand('copy')) {
        alert('クリップボードにコピーしました');
    } else {
        alert('ご利用のブラウザは対応しておりません');
    }
  });
});
